import request from '@/assets/utils/request'

export function get_index_data(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_index_data',
        method: 'POST',
        data
    })
}
export function get_header_footer_data(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_header_footer_data',
        method: 'POST',
        data
    })
}
export function feedback(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/feedback',
        method: 'POST',
        data
    })
}
export function get_item_category(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_item_category',
        method: 'POST',
        data
    })
}
export function get_item_list(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_item_list',
        method: 'POST',
        data
    })
}
export function get_item_info(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_item_info',
        method: 'POST',
        data
    })
}
export function get_article_category(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_article_category',
        method: 'POST',
        data
    })
}
export function get_article_list(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_article_list',
        method: 'POST',
        data
    })
}
export function get_article_info(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/get_article_info',
        method: 'POST',
        data
    })
}
export function about(data) {
    return request({
        url: 'http://www.headpan.com/public/api.php/index/about',
        method: 'POST',
        data
    })
}
